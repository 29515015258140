<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1>Manage Action Types</h1>
          <div id="component-breadcrumbs">
            <b-breadcrumb
              class="breadcrumb-slash"
              :items="breadcrumbItems"
            />
          </div>
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <div class="col-2 pr-0 pl-0 D-flex justify-space-arround">
              <b-button
                block
                variant="primary"
                @click="$router.push('/admin/utility/add-action-types')"
              >
                <span>Add Action Type</span>
              </b-button>
            </div>
          </div>
          <br>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Name -->
              <span
                v-if="props.column.field === 'Name'"
                class="text-nowrap"
              >
                <span class="text-nowrap">{{ props.row.Name }}</span>
              </span>

              <!-- Column: Class -->
              <span
                v-else-if="props.column.field === 'className'"
                class="text-center"
              >
                <div>{{ ActionClassFilter(props.row.ActionClassID) }}</div>
              </span>

              <!-- Column: IsNonBillable -->
              <span
                v-else-if="props.column.field === 'IsNonBillable'"
                class="text-center"
              >
                <div>{{ props.row.IsNonBillable | IsBillable }}</div>
              </span>

              <!-- Column: Common -->
              <span v-else-if="props.column.field === 'Action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item>
                      <span class="p-1 pr-3" @click="editActionType(props.row.ActionTypeID)">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <span class="p-1 pr-3" @click="deleteActionType(props.row)">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-col>
    <b-modal
      id="modal-delete-action-type"
      ok-only
      ok-variant="danger"
      ok-title="Delete Action"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Action"
      @ok="confirmDeleteAction()"
    >
      <div v-if="actionToDelete !== null">
        Are you sure you want to delete Action type {{ actionToDelete.Name }}. This cannot be undone.
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import {VueGoodTable} from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import store from "@/store";
import APIService from "@core/utils/APIService";
import {BBreadcrumb, BButton, BCol, BDropdown, BDropdownItem, BPagination, BRow} from "bootstrap-vue";

const apiService = new APIService();

export default {
  components: {
    BBreadcrumb,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    BRow,
    BCol,
    BButton,
  },
  filters: {
    IsBillable(val) {
      return val ? "N" : "Y";
    }
  },
  data() {
    return {
      actionToDelete: null,
      actionClassOption: [],
      rows: [],
      columns: [
        {
          label: "Action Name",
          field: "Name",
        },
        {
          label: "Class",
          field: "className",
          sortable: true,
          sortFn: this.sortFn,
        },
        {
          label: "Is Billable (Y/N)",
          field: "IsNonBillable",
          type: "boolean",
          thClass: "vgt-center-align",
        },
        {
          label: "Action",
          field: "Action",
        },
      ],
      breadcrumbItems: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: "Utility",
          href: '/admin/utility'
        },
        {
          text: "Manage Action Types",
          active: true,
        },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  async created() {
    try {
      this.getActiveClasses();
      this.loadActionTypes();
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    getActiveClasses() {
      apiService
          .get("action-class")
          .then(res => {
            this.actionClassOption = res.data.map(item => {return {title: item.name, value: item.id}});
          })
    },
    loadActionTypes() {
      apiService
          .get("action/types")
          .then((response) => {
            this.rows = response.data;
            this.rows.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0))
          });
    },
    editActionType(ActionTypeID) {
      this.$router.push('/admin/utility/edit-action-types/' + ActionTypeID);
    },
    deleteActionType(obj) {
      this.actionToDelete = obj;
      this.$bvModal.show("modal-delete-action-type");
    },
    confirmDeleteAction() {
      apiService
          .delete("action/type/" + this.actionToDelete.ActionTypeID)
          .then((response) => {
            this.actionToDelete = null;
            this.loadActionTypes();
          });
    },
    ActionClassFilter(val) {
      if (val && this.actionClassOption && this.actionClassOption.length) {
        return this.actionClassOption.filter(i => i.value === val)[0].title
      } else {
        return val
      }
    },
    sortFn(x, y, col, rowX, rowY) {
      return (this.ActionClassFilter(rowX.ActionClassID) < this.ActionClassFilter(rowY.ActionClassID) ? -1 :
          (this.ActionClassFilter(rowX.ActionClassID) > this.ActionClassFilter(rowY.ActionClassID) ? 1 : 0));
    },
  },
}
</script>

<style scoped>

</style>